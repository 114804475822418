<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <el-date-picker
                v-model="filter.date_range"
                type="daterange"
                size="small"
                :picker-options="date_picker_options"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker> &nbsp;
              <el-select size="small" v-model="filter.status_filter" placeholder="Select Status">
                <el-option
                  v-for="item in statusFilterOptions"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
                </el-option>
              </el-select> &nbsp;
              <!-- <el-select v-model="filter.status_filter" size="small">
                <el-option v-for="(item, i) in status_list" :key="i" :value="item.value" :label="item.label" />
              </el-select> -->
              <el-button :loading="loading.table" @click="page = 1; getReportList();" size="small" class="btn-default" icon="el-icon-search">Search</el-button>
              <el-button :loading="loading.table" @click="downloadReport();" size="small" class="btn-primary" icon="el-icon-download">Download</el-button>
            </div>
            <hr/>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Tiket No</th>
                    <th>Status</th>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Started At</th>
                    <th>Resolved At</th>
                    <th>Duration</th>
                    <th>Channel</th>
                    <th>Group</th>
                    <th>Agent Name</th>
                    <th>First Response Date</th>
                    <th>First Response Time</th>
                    <th>Labels</th>
                    <th>Time</th>
                    <th>Name</th>
                    <th>From</th>
                    <th>Chat</th>
                    <th>Order</th>
                  </tr>
                </thead>
                <tbody v-loading="loading.table">
                  <tr v-for="data in list" :key="data.id">
                    <td>{{ data.ticket_no }}</td>
                    <td>{{ data.status }}</td>
                    <td>{{ data.customer_name }}</td>
                    <td>{{ data.customer_phone }}</td>
                    <td>{{ data.started_at }}</td>
                    <td>{{ data.resolved_at }}</td>
                    <td>{{ data.duration }}</td>
                    <td>{{ data.channel }}</td>
                    <td>{{ data.group }}</td>
                    <td>{{ data.agent_name }}</td>
                    <td>{{ data.first_response_date }}</td>
                    <td>{{ data.first_response_time }}</td>
                    <td>{{ data.labels }}</td>
                    <td>{{ data.time }}</td>
                    <td>{{ data.name }}</td>
                    <td>{{ data.from }}</td>
                    <td>{{ data.chat }}</td>
                    <td>{{ data.order }}</td>
                    <!-- <td>
                      <el-tag size="mini" :type="data.status_color">
                        {{ data.status_str }}
                      </el-tag>
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <b-pagination v-if="count > per_page" v-model="currentPage" :total-rows="count" :per-page="per_page"/>
              <el-empty v-if="!count" description="No data"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { isEqual } from 'lodash';
import reportApi from '../../../api/reports';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'ReportSessionDetail',
  metaInfo: {
    title: 'Report Session',
  },
  data() {
    return {
      list: [],
      count: 0,
      per_page: 50,
      loading: {
        table: true,
      },
      filter: {
        page: this.$route.query.page || 1,
        date_range: [
          this.$route.query?.start_date || moment().subtract(7, 'days'),
          this.$route.query?.end_date || moment(),
        ],
        status_filter: this.$route.query?.status_filter || 'handling',
      },
      statusFilterOptions: [
        {
          value: 'handling',
          text: 'Handling',
        },
        {
          value: 'bot',
          text: 'Bot',
        },
      ],
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getReportList();
    loader.hide();
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.filter.page;
      },
      set(val) {
        this.filter.page = val;
        this.getReportList();
      },
    },
    company_id() {
      return this.$store.state.backOffice.activeCompany;
    },
    token() {
      return this.$store.state.backOffice.token;
    },
  },
  methods: {
    async getReportList() {
      const options = {
        page: this.filter.page.toString(),
        per_page: this.per_page.toString(),
        start_date: moment(this.filter.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.filter.date_range[1]).format('YYYY-MM-DD'),
        status_filter: this.filter.status_filter,
      };
      this.loading.table = true;
      const { data } = await reportApi.chatlogList({
        workspace_id: this.activeWorkspace._id,
        query: options,
      }).catch((err) => console.log(err));
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      this.list = data.new_result;
      this.count = data.count;
      this.loading.table = false;
    },
    async downloadReport() {
      this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.loading.table = true;
            const options = {
              page: this.filter.page,
              per_page: this.per_page.toString(),
              start_date: moment(this.filter.date_range[0]).format('YYYY-MM-DD'),
              end_date: moment(this.filter.date_range[1]).format('YYYY-MM-DD'),
              status_filter: this.filter.status_filter,
            };
            const response = await reportApi.downloadChatlogList({
              workspace_id: this.activeWorkspace._id,
              company_id: this.company_id,
              token: this.token,
              query: options,
            }).catch(() => {});
            this.loading.table = false;
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              title: this.$t('general.success'),
              type: 'success',
              message: this.$t('general.waiting_download'),
              duration: 10 * 1000,
              showClose: true,
            });
          }
          cb();
        },
      });
    },
  },
};
</script>
